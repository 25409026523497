<template lang="pug">
</template>
<script>
import isMobile from 'ismobilejs';

export default {
  props: ['id'],
  data() {
    return {
      isMobile: isMobile(window.navigator).any,
    };
  },
  async created() {
    const clientType = this.isMobile ? 'mobile' : 'desktop';
    const page = (await this.$axios.get(`https://backoffice.umax.dev/api/v1/zoom/meeting/${this.id}/${clientType}`)).data;
    /* const page = (await this.$axios.get(`https://apterion.loca.lt/api/v1/zoom/meeting/${this.id}/${clientType}`)).data; */
    if (page.platform === 'zoom') {
      console.log('zoom');
      console.log(page.link);
      window.location.replace(`/zoom/${page.link}`);
    } else if (page.platform === 'teams') {
      console.log('teams');
      console.log(page.link);
      window.location.replace(`/teams/${page.link}`);
    }
  },
};
</script>
